<template>
  <div :class="$style.root">
    <v-menu v-model="show" absolute offset-y @close="distributor = null">
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-data-table
                  v-if="items"
                  dense
                  must-sort
                  item-key="name"
                  :loading="pending"
                  loading-text="Загрузка..."
                  :class="$style.table"
                  :items-per-page="5"
                  :hide-default-footer="items.length < 6"
                  :items="items"
                  :headers="[
              {value: 'distributor.name', text: 'Дистрибьютор'},
              {value: 'sum', text: 'Сумма, руб'},
            ]"
                  :item-class="() => $style.tr"
                  :footer-props="{
              showFirstLastPage: true,
              itemsPerPageOptions: [5, 10, 20, -1],
              itemsPerPageText: '',
            }"
                  @click:row="onClick"
          >
            <template v-slot:footer>
              <div :class="$style.total">
                Итого: {{sum}} руб.
              </div>
            </template>
          </v-data-table>
          <div v-if="pending" :class="$style.loader" />
        </div>
      </template>
      <div :class="$style.menu">
        <realization-cislink-table :inn="inn" :distributor="distributor" />
      </div>
    </v-menu>
  </div>
</template>

<script>
  import { get, includes, toString } from 'lodash';
  import RealizationCislinkTable from '@/components/RealizationCislinkTable/RealizationCislinkTable';
  import { mapActions, mapGetters } from 'vuex';
  export default {
    name: 'RealizationCislinkDistributor',
    components: {
      RealizationCislinkTable,
    },
    props: {
      inn: { type: String, required: true },
    },
    data() {
      return {
        show: false,
        editing: false,
        distributor: null,
      };
    },
    computed: {
      ...mapGetters({
        pending: 'realizationsCislink/pending',
        items: 'realizationsCislink/distributors',
        sum: 'realizationsCislink/sum',
      }),
    },
    methods: {
      ...mapActions({
        fetchDistributors: 'realizationsCislink/fetchDistributors',
        clearDistributors: 'realizationsCislink/clearDistributors',
      }),
      onUpdate() {
        if (includes([10, 12], toString(this.inn).length)) {
          this.fetchDistributors({
            inn: this.inn,
          }).catch(() => {
            this.clearDistributors();
          });
        } else {
          this.clearDistributors();
        }
      },
      onClick(item){
        if (!this.show) {
          this.distributor = get(item, 'distributor.id', null);
        }
      }
    },
    watch: {
      'inn': {
        immediate: true,
        handler() {
          this.onUpdate();
        }
      },
    },
  }
</script>

<style module lang="scss">
  .root {
    position: relative;
    :global {
      .v-data-footer{
        justify-content: space-between;
        padding: 0;
        .v-data-footer__select {
          .v-input {
            min-width: 60px;
          }
        }
        .v-data-footer__pagination {
          min-width: 70px;
        }
        .v-select {
          margin-left: 0 !important;
        }
      }
    }
  }
  .inner {
    position: relative;
  }
  .table {}
  .tr {
    cursor: pointer;
    td {
      :not(.v-data-table__mobile-row) {
        width: 50%;
      }
    }
  }
  .total{
    padding: 6px 16px;
    font-size: 14px;
    font-weight: bold;
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .menu{
    width: 420px;
    max-width: 100%;
    max-height: 50vh;
    overflow: auto;
  }
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
